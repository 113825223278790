import React from "react";
import Fade from 'react-reveal/Fade';
import {Helmet} from "react-helmet";

import ContactForm from "./ContactForm";
import './Contact.css'



const Contact = () => {

    return (
      <>

            <Helmet>
                  <meta charSet="utf-8" />
                  <title>CONTACT US - Commonwealth Rehab</title>
                  <link rel="canonical" href="https://betterdaysrecovery.biz/" />
            </Helmet>

<div className='contact-hero main-menu'>

            <div className="contact-hero-content">
            <Fade top>
                    <h1>HAVE SOME QUESTIONS?</h1>
            </Fade>
            <Fade right>
                  <p>Our Addiction and Recovery Support team is available to assist you. </p>
            </Fade>
            <Fade right>
                  <h1>323 443-9950</h1>
            </Fade>

            </div>
     </div>

      <ContactForm />

      </>
    )
}

export default Contact
import React from 'react';
import './Hero.css';
import {Helmet} from "react-helmet";
import { FaLongArrowAltRight } from "react-icons/fa"; // Import the arrow icon
import { Link } from 'react-router-dom'; // For routing between pages
import ContactForm from '../../components/contact/Contact2';

const Hero = () => {

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smoothly scroll to the top
      };


    return (
        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>HOME - Commonwealth Rehab</title>
            <link rel="canonical" href="https://betterdaysrecovery.biz/" />
        </Helmet>

            <div className='hero'>

                <div className="hero-container container">

                        <div className='hero-caption'>
                        <h1>Together, We’ll Find Your Better Days.</h1>
                        <p>Discover a supportive environment where your mental health journey starts. We provide the tools, guidance, and care needed to nurture your mind, helping you achieve lasting peace and balance. Your path to emotional wellbeing begins with us, right here.</p>
                            <div className='hero-btn'>
                                <Link to="/inpatient-rehab" onClick={scrollToTop}>
                                    <button className='hero-btn1'>
                                        Inpatient <FaLongArrowAltRight className='hero-arrow' />
                                    </button>
                                </Link>
                                <Link to="/outpatient-rehab" onClick={scrollToTop}>
                                <button className='hero-btn2'>
                                    Outpatient <FaLongArrowAltRight className='hero-arrow' />
                                </button>
                                </Link>
                            </div>
                        </div>

                        <div className='hero-form'>
                            <ContactForm />
                        </div>

                </div>
            </div>
        </>
    );
}

export default Hero;

import React, { useRef, useState } from "react";
import { BsFillPinMapFill } from 'react-icons/bs';
import { AiOutlinePhone } from 'react-icons/ai';
import { AiOutlineMail } from 'react-icons/ai';

import './ContactForm.css';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState("Submit");

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_bdfl0ie', 'template_u4oh51i', form.current, 'CLcHWAKSemVMd1_sU')
            .then(
                (result) => {
                    console.log(result.text);
                    setButtonText("Message Delivered");
                },
                (error) => {
                    console.log(error.text);
                }
            );

        e.target.reset();
    };

    return (
        <>
            <div className="contactform">
                <div className="contactform-header contact2-container">
                    <h1>DO YOU NEED URGENT HELP?</h1>
                    <p>Our Addiction and Recovery Support team is available to assist you.</p>
                </div>

                <section className="container">
                    <div>
                        <div className="footer_get_touch_inner">
                            <div className="get_form_inner">
                                <div className="get_form_inner_text">
                                    <h3>Get In Touch</h3>
                                    <p><i>We'll keep your contact information strictly confidential.</i></p><br />
                                </div>
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="inputs">
                                        <input type="text" name='name' placeholder='Your Name' required /> <br />
                                        <input type="text" name='email' placeholder='Your Email' required /> <br />
                                        <input type="tel" name='phone' placeholder='Your Phone' required /> <br />
                                        <input type="text" name='subject' placeholder='Subject' required /> <br />
                                    </div>
                                    <div>
                                        <textarea name="message" placeholder="How can we help?" cols="30" rows="10" required></textarea>
                                        <input type="submit" value={buttonText} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="contact-cards-container">
                    <div className="get_form_inner2">
                        <h2>CALL US</h2>
                        <p><AiOutlinePhone /><a href="tel:3234439950"> +1 323 443-9950</a></p>
                    </div>
                    <div className="get_form_inner2">
                        <h2>EMAIL</h2>
                        <p><AiOutlineMail /> info@commonwealthrehab.biz </p><br />
                    </div>
                    <div className="get_form_inner2">
                        <h2>LOCATION</h2>
                        <p><BsFillPinMapFill /> 515 W. Commonwealth Ave. #211, Fullerton CA 92832 </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactForm;
